import { createContext, useContext, useState } from "react";

// Context for selected files
const FileCtx = createContext({
  selectedFiles: [],
  setSelectedFiles: function () {},
});

// Context for selected folder ID
const FolderCtx = createContext({
  selectedFolderId: null,
  setSelectedFolderId: function () {},
});

export default function DocumentContext({ children }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);

  return (
    <FileCtx.Provider value={{ selectedFiles, setSelectedFiles }}>
      <FolderCtx.Provider value={{ selectedFolderId, setSelectedFolderId }}>
        {children}
      </FolderCtx.Provider>
    </FileCtx.Provider>
  );
}

// Custom hooks to use the contexts
export const useDocumentCtx = () => useContext(FileCtx);
export const useFolderCtx = () => useContext(FolderCtx);
