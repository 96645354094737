import { Button, Dropdown, message, Modal, Switch } from "antd";
import { FcFolder } from "react-icons/fc";
import { GoFileDirectory } from "react-icons/go";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router";
import {
  CiShare2,
  CiEdit,
  CiTrash,
  CiCircleInfo,
  CiSaveDown2,
} from "react-icons/ci";
import { useState } from "react";
import DocumentNameChanger from "./document.nameChanger";
import DocumentRemover from "./remover";
import {
  deleteTemporaryFolder,
  getFilePaths,
  getFiles,
  getPublicPaths,
  shareFolder,
} from "../../services/document";
import { useTranslation } from "react-i18next";
import Darkness from "../../lib/darkness";
import { IoLink, IoMove } from "react-icons/io5";
import copier from "../../utils/copier";
import {
  useDocumentCtx,
  useFolderCtx,
} from "../../pages/Documents/Document.Context";

const FolderSharing = (props) => {
  const { t } = useTranslation();
  const [isPublic, setIsPublic] = useState(props.data.isPublic);

  const getSharingLink = async () => {
    await copier(process.env.REACT_APP_SHARE_FOLDER + "/" + props.data.code);
  };

  const onChange = async (checked) => {
    await shareFolder({ id: props.data.id, isPublic: checked });
    setIsPublic(checked);
  };
  return (
    <Modal {...props}>
      <div className="space-x-4 flex items-center">
        <Switch onChange={onChange} checked={isPublic} />
        <span className="px-2 py-1 block">
          {t("docPage.chia_se_public_note")}
        </span>
      </div>
      <Button
        onClick={getSharingLink}
        className="mt-3 shadow-m flex items-center"
        icon={<IoLink />}
      >
        {t("docPage.sao_chep_link")}
      </Button>
    </Modal>
  );
};

export default function DocumentFolder({
  data,
  isFullPermission,
  isRoot = false,
}) {
  const nav = useNavigate();
  const [openSharing, setOpenSharing] = useState(false);
  const [isOpenNameChanger, setIsOpenNameChanger] = useState({
    open: false,
    data: undefined,
  });
  const [openRemover, setOpenRemover] = useState({
    open: false,
    data: undefined,
  });
  const { setSelectedFolderId } = useFolderCtx();
  const { setSelectedFiles } = useDocumentCtx();
  const { t } = useTranslation();

  const downloadFolder = async () => {
    const res = await getFilePaths({ folderId: data.id });
    if (res.data) {
      res.data.forEach((url, index) => {
        setTimeout(() => {
          const link = document.createElement("a");
          link.href = url;
          link.download = "";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, index * 1000);
      });
    }
  };

  const moveFolder = async (data) => {
    console.log(data);
    setSelectedFolderId(data.id);
    setSelectedFiles([]);
  };

  const menu = [
    {
      label: (
        <div
          className="min-w-[200px]"
          onClick={() => setIsOpenNameChanger({ open: true, data })}
        >
          {t("docPage.doi_ten")}
        </div>
      ),
      icon: <CiEdit />,
      key: "1",
      show: isFullPermission,
    },
    {
      label: <div className="min-w-[200px]">{t("docPage.thong_tin")}</div>,
      icon: <CiCircleInfo />,
      key: "2",
      show: true,
    },
    {
      onClick: () => setOpenSharing(true),
      label: <div className="min-w-[200px]">{t("docPage.chia_se")}</div>,
      icon: <CiShare2 />,
      key: "3",
      show: isFullPermission,
    },
    {
      onClick: () => downloadFolder(),
      label: <div className="min-w-[200px]">{t("docPage.tai_cac_file")}</div>,
      icon: <CiSaveDown2 className="" />,
      key: "4",
      show: true,
    },
    {
      onClick: () => setOpenRemover({ open: true, data }),
      label: (
        <div className="min-w-[200px] text-red-500">{t("docPage.xoa")}</div>
      ),
      icon: <CiTrash className="text-red-500" />,
      key: "5",
      show: isFullPermission,
    },
    {
      onClick: () => moveFolder(data),
      label: <div className="min-w-[200px]">{t("docPage.move_folder")}</div>,
      icon: <IoMove />,
      key: "5",
      show: isFullPermission,
    },
  ];
  const visibleMenu = menu.filter((item) => item.show);

  const submitDeleteFolder = async () => {
    const res = await deleteTemporaryFolder(data.id);

    console.log(res);

    message.success(res.message);
    setOpenRemover({ open: false, data: undefined });
    Darkness.publish("reload-folders", data.parentFoldeId);
  };

  return (
    <div
      onClick={() => nav(`/documents/${data.id}`)}
      className="shadow-sm cursor-pointer bg-gray-100 rounded-lg px-2 py-3 hover:bg-gray-200"
    >
      <div onClick={(e) => e.stopPropagation()}>
        <DocumentNameChanger
          data={isOpenNameChanger.data}
          open={isOpenNameChanger.open}
          onCancel={() =>
            setIsOpenNameChanger({ open: false, data: undefined })
          }
        />

        <DocumentRemover
          open={openRemover.open}
          data={openRemover.data}
          onCancel={() => setOpenRemover({ open: false, data: undefined })}
          action={submitDeleteFolder}
        />

        <FolderSharing
          open={openSharing}
          title={`${t("docPage.chia_se")} : ${data.folderName}`}
          footer={null}
          data={data}
          onCancel={() => setOpenSharing(false)}
        />
      </div>
      <div className="tieu_de flex relative space-x-1 items-center px-2">
        <FcFolder className="text-xl" />
        <span className="txt">{data.folderName}</span>
        {!isRoot && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="right-0 absolute"
          >
            <Dropdown trigger={["click"]} menu={{ items: visibleMenu }}>
              <HiOutlineDotsVertical className="text-lg" />
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
}
