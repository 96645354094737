import { APIClient } from "../utils/axiosCustomize";
import { endpoints } from "../utils/constants/endpoints";

const axios = new APIClient();

export const addAttachmentToDocument = async ({ contentId, fileId }) => {
  const res = await axios.post(`/AttachFile/insert`, { contentId, fileId });
  return res;
};

export const deleteAttachmentFromDoc = async (attachmentId) => {
  const res = await axios.delete(`/AttachFile/delete?id=${attachmentId}`);
  return res;
};

export const insertFile = async (body) =>
  await axios.post(`/FileUpload/insert`, body);

export const getFiles = async (query) =>
  await axios.get(`/FileUpload/getFileUploads`, query);

export const deleteFile = async (id) =>
  await axios.delete(`/FileUpload/delete?id=${id}`);

export const createFolder = async (body) => {
  return await axios.post("/Folder/insert", {
    ...body,
    folderName: String(Date.now()),
    isPublic: false,
  });
};

export const createRootFolder = async (body) => {
  return await axios.post("/Folder/insert-root", {
    ...body,
    folderName: String(Date.now()),
    isPublic: false,
  });
};

export const getSites = async () => {
  return await axios.get("/Site/getSites?pageSize=50");
};

export const getFileDetail = async (fileId) =>
  await axios.get(`/FileUpload/getById?id=${fileId}`);

export const getFilePublic = async (code) =>
  await axios.get(`/FileUpload/GetFilePublicCode?code=${code}`);

export const deleteFolder = async (id) =>
  await axios.delete(`/Folder/delete?id=${id}`);

export const changeFolderName = async (body) => {
  return await axios.put("/Folder/update", { ...body });
};

export const getTrash = async (query) =>
  await axios.get("/Folder/getTempDelete", { ...query });

export const changeRootFolderName = async (body) => {
  return await axios.put("/Folder/update-root", { ...body });
};

export const deleteTemporaryFile = async (id) =>
  await axios.delete(`/FileUpload/delete-temp?id=${id}`);
export const deleteTemporaryFolder = async (id) =>
  await axios.delete(`/Folder/delete-temp?id=${id}`);

export const emptyTrash = async () =>
  await axios.delete("/FileUpload/delete-all-tempdelete");

export const deletePermanently = async (items) =>
  await axios.delete("/FileUpload/delete-list", { data: items });

export const recover = async (items) =>
  await axios.put("/FileUpload/recovery-list", items);

export const changeFileName = async (body) =>
  await axios.put("/FileUpload/update", body);

export const getFolders = async (query) => {
  return await axios.get("/Folder/getFolders", { ...query });
};

export const getRootFolders = async (query) => {
  return await axios.get("/Folder/getFolderRoots", { ...query });
};

export const getFolderDetail = async (id) => {
  return await axios.get(`/Folder/getById`, { id });
};

export const moveFiles = async (body) => {
  return await axios.put("/FileUpload/move-file", body);
};

export const moveFolder = async (body) => {
  return await axios.put("/Folder/move", body);
};

export const getListPublicDocument = async (query) => {
  const { data, totalCount, pageSize, pageIndex } = await axios.get(
    endpoints.Document + "/getContentsPublic",
    {
      sortBy: "createDate",
      sortType: "desc",
      ...query,
    }
  );

  const dataWithKey = data
    ? data.map((i) => {
        return { ...i, key: i.Id };
      })
    : [];

  return { data: dataWithKey, totalCount, pageSize, pageIndex };
};

export const getListDocument = async (query) => {
  const { data, totalCount, pageSize, pageIndex } = await axios.get(
    endpoints.Document + "/getContentsPrivate",
    {
      sortBy: "createDate",
      sortType: "desc",
      ...query,
    }
  );

  const dataWithKey = data
    ? data.map((i) => {
        return { ...i, key: i.Id };
      })
    : [];

  return { data: dataWithKey, totalCount, pageSize, pageIndex };
};

export const getDocumentDetail = async (id) => {
  const { data } = await axios.get(endpoints.Document + `/getById`, { id });
  return data;
};

export const insertDocument = async (payload) => {
  const res = await axios.post(endpoints.Document + "/insert", payload);
  return res;
};

export const deleteDocument = async (id) => {
  const data = await axios.delete(endpoints.Document + `/delete?id=${id}`);
  return data;
};

export const updateDocument = async ({
  id,
  title,
  description,
  contentData,
  departmentId,
  isPublic,
  isActive,
  tagIds,
  slugUrl,
}) => {
  const { data } = await axios.put(endpoints.Document + "/update", {
    id,
    title,
    description,
    contentData,
    departmentId,
    isPublic,
    isActive,
    tagIds,
    slugUrl,
  });
  return data;
};

export const shareFile = async ({ id, isPublic }) =>
  await axios.put("/FileUpload/change-public", { id, isPublic });

export const shareFolder = async ({ id, isPublic }) =>
  await axios.put("/Folder/change-public", { id, isPublic });

export const getFolderPublicDetail = async (folderCode) =>
  await axios.get(`/Folder/GetFolderPublicByCode?code=${folderCode}`);

export const getPublicFiles = async (query) =>
  await axios.get(`/FileUpload/getPublicFileUploads`, query);

export const getPublicPaths = async (query) =>
  await axios.get(`/FileUpload/getPublicFilePaths`, query);

export const getFilePaths = async (query) =>
  await axios.get(`/FileUpload/getFilePaths`, query);

export const getDocumentDateRange = async ({ startDate, endDate }) => {
  const { success, message, data, errorCode } = await axios.get(
    endpoints.Document + "/getSumaryContentUpload",
    {
      startDate,
      endDate,
    }
  );

  return { data, success, message, errorCode };
};

export const getAllTags = async (query) => {
  const { data, totalCount, pageSize, pageIndex } = await axios.get(
    endpoints.Tag + "/getTags",
    {
      ...query,
      sortBy: "TagName",
    }
  );

  const dataHasValue = data
    ? data.map((i) => ({ ...i, value: i.tagName }))
    : null;

  return { data: dataHasValue, totalCount, pageSize, pageIndex };
};
